import React from "react"
import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"

import AssetImage from "../components/AssetImage"
import Layout from "../components/Layout"
import SEO from "../components/SEO/SEO"
import { NewsletterBanner } from "../components/NewsletterBanner"

const shortcodes = {
  Link,
}

const PageTemplate = ({ data, location }) => {
  const page = data.mdx;
  const siteTitle = data.site.siteMetadata?.title || `Manu Sobles `;
  const showSubscribe = page.frontmatter.showSubscribe === null || page.frontmatter.showSubscribe;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={page.frontmatter.title}
        description={page.frontmatter.excerpt}
        imagePath={page.frontmatter.imgPath}
        imageAlt={page.frontmatter.imgAlt}
        pathName={page.frontmatter.slug}
        article={false}
      />

      <section
        className="page"
        itemScope
      >
        <header className="max-w-screen-sm mx-auto mt-12 mb-10">
          <h1 itemProp="headline">{page.frontmatter.title}</h1>

          <div className="text-2xl">{page.frontmatter.description}</div>
        </header>

        {page.frontmatter.imgPath && (
          <div className="block mb-10">
            <AssetImage
              imgClassName="rounded"
              imgPath={page.frontmatter.imgPath}
              imgAlt={page.frontmatter.imgAlt}
              imgCaption={page.frontmatter.imgCaption}
            />
          </div>
        )}

        <section itemProp="pageBody" className="max-w-screen-sm mx-auto mb-12">
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{page.body}</MDXRenderer>
          </MDXProvider>
        </section>

        <NewsletterBanner showSubscribe={showSubscribe} />
      </section>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug(
    $id: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt
      timeToRead
      body
      frontmatter {
        slug
        title
        description
        date
        imgPath: img_path
        imgAlt: img_alt
        imgCaption: img_caption
        showSubscribe
      }
    }
  }
`
